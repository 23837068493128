export const TYPES = {
    // Recipes
    GET_RECIPES_REQUEST: "GET_RECIPES_REQUEST",
    GET_RECIPES_SUCCESS: "GET_RECIPES_SUCCESS",
    GET_RECIPE_REQUEST: "GET_RECIPE_REQUEST",
    GET_RECIPE_SUCCESS: "GET_RECIPE_SUCCESS",
    GET_RECIPES_BY_TEXT_REQUEST: "GET_RECIPES_BY_TEXT_REQUEST",
    GET_RECIPES_BY_TEXT_SUCCESS: "GET_RECIPES_BY_TEXT_SUCCESS",

    // Failture
    FAILTURE: "FAILTURE",
};
