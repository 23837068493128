import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Loading } from "../../components/loading";
import { Record } from "../../components/record";

import { getRecipesRequest } from "../../store/recipes/actions";

export const Type = () => {
    const dispatch = useDispatch();
    const { type } = useParams();

    const { recipes, isLoading } = useSelector((state) => state.recipes);

    useEffect(() => dispatch(getRecipesRequest(type)), [dispatch, type]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <section className="type">
                    <Record />
                    {recipes.map((recipe, idx) => (
                        <Record
                            url={`../recipe/${recipe.url}`}
                            value={recipe.name}
                            key={idx}
                        />
                    ))}
                </section>
            )}
        </>
    );
};
