import { TYPES } from "../types";

const initialState = {
    isLoading: true,
    isError: false,
    recipes: [],
    recipe: {},
    recipesByText: [],
};

export const recipesReducer = (state = initialState, action) => {
    switch (action.type) {
        // Получение всех рецептов
        case TYPES.GET_RECIPES_REQUEST:
            return { ...state, isLoading: true, isError: false };

        case TYPES.GET_RECIPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                recipes: action.recipes,
            };

        // Получение рецептов по тексту
        case TYPES.GET_RECIPES_BY_TEXT_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_RECIPES_BY_TEXT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                recipesByText: action.recipes,
            };

        // Получение рецепта
        case TYPES.GET_RECIPE_REQUEST:
            return { ...state, isLoading: true, isError: false };
        case TYPES.GET_RECIPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                recipe: action.values,
            };

        // FAILURE
        case TYPES.FAILTURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                Error: action.error,
            };

        default:
            return state;
    }
};
