import { put, call, takeLatest } from "redux-saga/effects";

import { ENDPOINTS } from "../endpoints";
import { TYPES } from "../types";
import { getRecipeSuccess, getRecipesSuccess, getRecipesByTextSuccess, getRecipesByTextError, requestFailure } from "./actions";

import { axiosInstance } from "../../utils/axios-instance";

// Получение всех рецептов определённого типа
function* getRecipes({ recipesType }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.RECIPES_ENDPOINT,
            { params: { type: recipesType } }
        );
        yield put(getRecipesSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

// Получение рецептов по тексту
function* getRecipesByText({ text }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.RECIPES_ENDPOINT,
            { params: { searchText: text } }
        );
        yield put(getRecipesByTextSuccess(data));
    } catch (err) {
        yield put(getRecipesByTextError(err));
    }
}

// Получение рецепта
function* getRecipe({ url }) {
    try {
        const { data } = yield call(
            axiosInstance.get,
            ENDPOINTS.RECIPE_ENDPOINT,
            { params: { url } }
        );
        yield put(getRecipeSuccess(data));
    } catch (err) {
        yield put(requestFailure(err));
    }
}

export default function* filesSaga() {
    yield takeLatest(TYPES.GET_RECIPE_REQUEST, getRecipe);
    yield takeLatest(TYPES.GET_RECIPES_BY_TEXT_REQUEST, getRecipesByText);
    yield takeLatest(TYPES.GET_RECIPES_REQUEST, getRecipes);
}
