import { TYPES } from "../types";

// Получение рецепта
export const getRecipeRequest = (url) => ({
    type: TYPES.GET_RECIPE_REQUEST,
    url,
});

export const getRecipeSuccess = (values) => ({
    type: TYPES.GET_RECIPE_SUCCESS,
    values,
});

// Получение всех рецептов
export const getRecipesRequest = (recipesType) => ({
    type: TYPES.GET_RECIPES_REQUEST,
    recipesType,
});

export const getRecipesSuccess = (recipes) => ({
    type: TYPES.GET_RECIPES_SUCCESS,
    recipes,
});

// Получение рецептов по тексту из поиска
export const getRecipesByTextRequest = (text) => ({
    type: TYPES.GET_RECIPES_BY_TEXT_REQUEST,
    text,
});

export const getRecipesByTextSuccess = (recipes) => ({
    type: TYPES.GET_RECIPES_BY_TEXT_SUCCESS,
    recipes,
});

export const getRecipesByTextError = (error) => ({
    type: TYPES.GET_RECIPES_BY_TEXT_SUCCESS,
    recipes: [],
});

// Ошибка
export const requestFailure = (error) => ({
    type: TYPES.FAILTURE,
    error,
});
