import React from "react";
import classnames from "classnames";

import "./input.sass";

export const Input = ({
  title,
  value,
  name,
  type = "text",
  placeholder = "",
  onChange = () => {},
  className,
  titleClass,
  readonly,
}) => (
  <div className="input__wrapper">
    <h3 className={classnames("input__title", titleClass)}>{title}</h3>
    <input
      name={name}
      type={type}
      value={value || ""}
      className={classnames("input", className)}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="off"
      readOnly={readonly}
    />
  </div>
);
