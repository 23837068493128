import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { format, parseISO } from "date-fns";

import { Loading } from "../../components/loading";
import { Record } from "../../components/record";

import { getRecipeRequest } from "../../store/recipes/actions";
import { convertToHtml } from "../../utils/convert-to-html";
import { getVideoIframe } from "../../utils/get-video-iframe";

import "./recipe.sass";

export const Recipe = () => {
    const dispatch = useDispatch();
    const { url } = useParams();

    const { recipe, isLoading } = useSelector((state) => state.recipes);

    useEffect(() => dispatch(getRecipeRequest(url)), [dispatch, url]);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="recipe">
                    <Record />
                    <h1 className="recipe__title">{recipe.name}</h1>
                    {recipe.date && (
                        <p className="recipe__date">
                            {format(parseISO(recipe.date), "dd.MM.yyyy")}
                        </p>
                    )}
                    {recipe.ingredients && (
                        <div className="recipe__text_wrapper">
                            <h2 className="recipe__subtitle">Ингредиенты</h2>
                            <p
                                className="recipe__ingredients"
                                dangerouslySetInnerHTML={convertToHtml(
                                    recipe.ingredients
                                )}
                            />
                        </div>
                    )}
                    <br />
                    {recipe.cooking && (
                        <div className="recipe__text_wrapper">
                            <h2 className="recipe__subtitle">Готовка</h2>
                            <p
                                className="recipe__cooking"
                                dangerouslySetInnerHTML={convertToHtml(
                                    recipe.cooking
                                )}
                            />
                        </div>
                    )}
                    {recipe.source && (
                        <iframe
                            className="recipe__source"
                            src={getVideoIframe(recipe.source)}
                            title={recipe.name}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            allowFullScreen
                        />
                    )}
                </div>
            )}
        </>
    );
};
