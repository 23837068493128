import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Record } from "../../components/record";
import { Input } from "../../components/input";

import { getRecipesByTextRequest } from "../../store/recipes/actions";

const TYPES = [
  { label: "sweet", value: "сладкое" },
  { label: "independent", value: "самостоятельные блюда" },
  { label: "meat", value: "мясные блюда" },
  { label: "garnish", value: "гарниры" },
  { label: "salad", value: "салаты" },
  { label: "product", value: "продукты" },
];

export const Main = () => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const dispatch = useDispatch();
  const { recipesByText } = useSelector((state) => state.recipes);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (!debouncedSearchValue) {
      return;
    }

    dispatch(getRecipesByTextRequest(debouncedSearchValue));
  }, [debouncedSearchValue]);

  return (
    <section className="main">
      <Input
        name="search"
        placeholder="Search..."
        value={searchValue}
        onChange={({ target }) => setSearchValue(target.value)}
      />
      {searchValue && recipesByText.length
        ? recipesByText.map((recipe, idx) => (
            <Record
              url={`../recipe/${recipe.url}`}
              value={recipe.name}
              key={idx}
            />
          ))
        : TYPES.map((type, idx) => (
            <Record url={`./type/${type.label}`} value={type.value} key={idx} />
          ))}
    </section>
  );
};
