import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { Main } from "../../pages/main";
import { Type } from "../../pages/type";
import { Recipe } from "../../pages/recipe";

export const App = () => (
    <div className="container">
        <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/type/:type" element={<Type />} />
            <Route exact path="/recipe/:url" element={<Recipe />} />

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </div>
);
