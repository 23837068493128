import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { recipesReducer } from "./recipes";

export const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        recipes: recipesReducer,
    });
