import React from "react";
import { useNavigate } from "react-router-dom";

import "./record.sass";

export const Record = ({ url, value = "←" }) => {
  const navigate = useNavigate();

  return (
    <div  className="record__block" onClick={() => navigate(url ?? -1)}>
      <p className="record">{value}</p>
    </div>
  );
};
